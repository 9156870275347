import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight1: '#FFFFFF',
      neutralLight2: '#FDFCF7',
      neutralLight3: '#F5F5F5',
      neutralLight4: '#FFF7FB',
      neutralLight5: '#E4F4F4',
      neutralLight6: '#F9F8F6',
      neutralDark1: '#000000',
      neutralDark2: '#464E53',
      primaryDark: '#10172E',
      primaryLight: '#B09132',
      danger: '#CC5858',
    },
  },
  fontFamily: {
    heading: "'Cormorant', serif",
    paragraph: "'Archivo', sans-serif",
  },
}

export type Styles = typeof styles

export const styles = {
  h1: css`
    margin: 1.5vw 0;
    font-size: 5.6vw;
    font-weight: 300;
    line-height: 0.9;
    font-style: italic;
    white-space: nowrap;
    text-transform: uppercase;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.neutralLight1};

    @media (max-width: 991px) {
      font-size: 11vw;
    }
  `,
  h2: css`
    font-size: 2.5vw;
    font-weight: 300;
    margin: 1.3vw 0;
    line-height: 1;
    text-transform: uppercase;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.primaryDark};

    @media (max-width: 991px) {
      font-size: 32px;
      margin-bottom: 16px;
    }
  `,
  h3: css`
    font-size: 1.8vw;
    font-weight: 300;
    margin: 1vw 0;
    line-height: 1;
    text-transform: uppercase;
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.variants.primaryDark};

    @media (max-width: 991px) {
      font-size: 20px;
      margin-bottom: 16px;
    }
  `,
  label: css`
    font-size: 0.75vw;
    margin-bottom: 1vw;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: ${theme.fontFamily.paragraph};
    color: ${theme.colors.variants.primaryLight};

    @media (max-width: 1599px) {
      font-size: 12px;
      margin-bottom: 16px;
    }
    @media (min-width: 2000px) {
      font-size: 16px;
    }
  `,
  description: css`
    font-size: 1vw;
    font-weight: 300;
    line-height: 1.8;
    font-family: ${theme.fontFamily.paragraph};
    color: ${theme.colors.variants.primaryDark};

    @media (max-width: 1599px) {
      font-size: 16px;
    }
    @media (min-width: 2000px) {
      font-size: 21px;
    }
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 1.5;
    }
  `,
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        <ParallaxProvider>{children}</ParallaxProvider>
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
